body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

canvas:hover {
  cursor: crosshair;
}

.forbidden-zone:hover {
  cursor: not-allowed;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}